import React from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom"
import { Container, Collapse, Nav, NavLink, Navbar, NavbarBrand, NavbarToggler, NavItem, Row, Col } from 'reactstrap'

export default () => (
  <Navbar expand="md" className="footer-nav">
    <Container className="d-block text-center">
      <Row className="d-inline-block footer-nav-links">
        <Col md="12">
          <a href="http://nyfadvertising.com/" target="_blank" className="footer-link">
            ADVERTISING AWARDS I
        </a>
          <a href="http://www.ameawards.com/" target="_blank" className="footer-link">
            AME AWARDS I
        </a>
          <a href="https://radio.newyorkfestivals.com" target="_blank" className="footer-link">
            RADIO AWARDS I
        </a>
          <a href="http://tvfilm.newyorkfestivals.com" target="_blank" className="footer-link">
            TV & FILM AWARDS
        </a>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <img className="footer-image" src="/public/img/navbar_brand.png" />
        </Col>
        <Col md="6" className="footer-nav-body text-left">
        641 LEXINGTON AVENUE, 13TH FL, NEW YORK, NY 10022 USA, TEL: 212-271-5278
        <br/>
        NEW YORK FESTIVALS INTERNATIONAL AWARD COMPETITIONS...CELEBRATING THE WORLD'S BEST WORK SINCE 1957.
        </Col>
        <Col md="3">
          <span className="Social media icons"></span>
        </Col>
      </Row>
    </Container>
  </Navbar >
)