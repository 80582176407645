import React from "react"
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom"
import { Form, Button, Input, FormGroup, Label, Col, Row, Card, CardFooter, CardBody, CardTitle, CardSubtitle } from 'reactstrap'

import GenericLoader from './generic-loader'
import ErrorMessage from './error-message'
import AwardCard from './award-card'
import AddlCreditsList from "./addl-credits-list";

class AwardForm extends React.Component {
  constructor(props) {
    super(props)
    const existingCartItem = props.customizedAward.cartItem
    const engravingLines = existingCartItem.engravingLines.map(engravingLine =>
      Object.assign({}, engravingLine, { originalTitle: engravingLine.title, originalValue: engravingLine.value }))
    const additionalCredits = (existingCartItem.additionalCredits || []).map(engravingLine =>
      Object.assign({}, engravingLine, { originalTitle: engravingLine.title, originalValue: engravingLine.value }))

    const cartItem = Object.assign({}, existingCartItem, { engravingLines, additionalCredits })
    this.state = {
      award: props.customizedAward.award,
      cartItem
    }
  }
  onAdditionalCreditsUpdated = (additionalCredits) => {
    const state = { cartItem: { ...this.state.cartItem, additionalCredits } }
    this.setState(state);
  }
  lineTitleChanged(inputIndex) {
    return (evt) => {
      const existingCartItem = this.state.cartItem
      const engravingLines = existingCartItem.engravingLines.map((line, lineIndex) => {
        return lineIndex === inputIndex ?
          Object.assign({}, line, { title: evt.target.value }) :
          line
      })
      const cartItem = Object.assign({}, this.state.cartItem, { engravingLines })
      this.setState({ cartItem })
    }
  }
  lineValueChanged(inputIndex) {
    return (evt) => {
      const existingCartItem = this.state.cartItem
      const engravingLines = existingCartItem.engravingLines.map((line, lineIndex) => {
        return lineIndex === inputIndex ?
          Object.assign({}, line, { value: evt.target.value }) :
          line
      })
      const cartItem = Object.assign({}, this.state.cartItem, { engravingLines })
      this.setState({ cartItem })
    }
  }
  onSubmit(evt) {
    evt.preventDefault()
    this.props.onSubmit(this.state.cartItem)
  }
  onUpdate(evt) {
    evt.preventDefault()
    this.props.onUpdate(this.state.cartItem).then(() => {
      this.clearModified()
    })
  }
  clearModified() {
    const existingCartItem = this.state.cartItem
    const engravingLines = existingCartItem.engravingLines.map(line =>
      Object.assign({}, line, { originalValue: line.value, originalTitle: line.title }))
    const cartItem = Object.assign({}, this.state.cartItem, { engravingLines })
    this.setState({ cartItem })
  }
  quantityUpdated = (e) => {
    const cartItem = { ...this.state.cartItem };
    cartItem.quantity = parseInt(e.target.value);
    this.setState({ cartItem });
  }
  quantityLeft = (e) => {
    if (!parseInt(e.target.value)) {
      e.target.value = 1;
    }
  }
  downloadClicked = (e) => {
    e.preventDefault();
    this.props.downloadClicked(this.state.cartItem);
  }
  render() {
    return (
      <div className={this.props.className || ''}>
        <Card>
          <CardBody>
            <Row>
              <Col sm="12" md="4">
                <AwardCard award={this.state.award} />
                <FormGroup row className="mt-2">
                  <Col sm="4">
                    <Label className="form-label form-label-lower">
                      Quantity
                    </Label>
                  </Col>
                  <Col sm="8">
                    <Input min="1" step="1" type="number" defaultValue={this.state.cartItem.quantity || 1} onBlur={this.quantityLeft} onChange={this.quantityUpdated} />
                  </Col>
                </FormGroup>
              </Col>
              <Col sm="12" md="8" >
                {this.props.showEntryItemId &&
                  <div>
                    <label>Entry Item ID #{this.state.cartItem.entryItemId || 'NONE'}</label>
                  </div>}
                {!this.props.disabled && (
                  <div className="mb-2">
                    {this.state.cartItem.engravingLines.map((line, index) => (
                      <FormGroup row key={index}>
                        <Col sm="4">
                          {line.action === 'Talent' && !this.props.editDisabled ?
                            <Input valid={line.title !== line.originalTitle} defaultValue={line.title} onChange={this.lineTitleChanged(index)} /> :
                            <Label className="form-label">{line.title}</Label>
                          }
                        </Col>
                        <Col sm="8">
                          {['Client', 'Talent'].indexOf(line.action) > -1 && !this.props.editDisabled ?
                            <Input valid={line.value !== line.originalValue} defaultValue={line.value} onChange={this.lineValueChanged(index)} /> :
                            <Label className="form-label form-label-lower">{line.value}</Label>
                          }
                        </Col>
                      </FormGroup>
                    ))}
                    {this.state.award.show_extended &&
                      <React.Fragment>
                        <Label className="form-label">Additional credits</Label>
                        <AddlCreditsList
                          lines={this.state.cartItem.additionalCredits}
                          onUpdate={this.onAdditionalCreditsUpdated}
                          canEdit={!this.props.editDisabled}
                        />
                      </React.Fragment>
                    }
                    {this.props.downloadClicked &&
                      <a href="#" onClick={this.downloadClicked}>Download all credits as XLSX</a>
                    }
                  </div>
                )}
                {this.props.disabled && (
                  <i>{this.props.disabled}</i>
                )}
              </Col>
            </Row>
          </CardBody>
          {!this.props.disabled && (
            <CardFooter style={{ textAlign: 'right' }}>
              {this.props.onSubmit ?
                <Button className="ml-2" onClick={this.onSubmit.bind(this)} >Add to cart</Button>
                : null}
              {this.props.onUpdate ?
                <Button className="ml-2" onClick={this.onUpdate.bind(this)}>Save changes</Button>
                : null}
              {this.props.onDelete ?
                <Button className="ml-2" onClick={this.props.onDelete.bind(this)}>Remove from cart</Button>
                : null}
            </CardFooter>
          )}
        </Card>
      </div>
    )
  }
}

export default withRouter(AwardForm)