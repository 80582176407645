import React from "react"
import { PulseLoader as Loader } from 'react-spinners'

export default (props) => (
  <div style={{ 'textAlign': 'center', 'width': '100%' }}>
    <Loader
      color={'#ccc'}
      loading={props.isLoading}
    />
  </div>
)