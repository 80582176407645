import React from "react"
import { Alert } from 'reactstrap'

export default (props) => {
  return props.message.length ?
    <Alert color="danger">
      <h4 className="alert-heading">An error has occurred.</h4>
      <p className="mb-0">{props.message}</p>
    </Alert> : null
}
