import axios from "axios";
import React, { useState } from "react";
import { useResource } from "../../hooks/useResource";
import { apiResponse } from "../../functions/apiResource";
import Orders from "./orders";
import GenericLoader from "../generic-loader";

export const OrdersView = () => {
  const [isWorking, setIsWorking] = useState(false);
  const { result, isLoading, ordersError, refreshOrders } = useResource(
    () => apiResponse(axios.get("/api/admin/orders")),
    []
  );

  async function approveOrder(order) {
    setIsWorking(true);
    try {
      await axios.post(`/api/admin/orders/${order.id}/approve`);
      refreshOrders();
    } catch (err) {
      console.error(err);
    } finally {
      setIsWorking(false);
    }
  }
  const { orders } = result ?? {};

  return (
    <>
      {orders?.length > 0 && (
        <Orders
          orders={orders}
          approve={approveOrder}
          refresh={refreshOrders}
        />
      )}
      <GenericLoader isLoading={isLoading || isWorking} />
    </>
  );
};
