import React from "react"
import { FormGroup, Row, Col, Label, Input } from 'reactstrap';

class CreditLine extends React.Component {
  constructor(props) {
    super(props);
    const { isNew, title, value } = props.line;
    this.titleRef = React.createRef();
    this.valueRef = React.createRef();
    this.state = {
      title: !isNew && title || '',
      value: !isNew && value || '',
    };
  }
  changed = (key, e) => {
    const line = { ...this.state, [key]: e.target.value };
    const { id, isNew } = this.props.line;
    this.setState(line);
    this.props.lineUpdated({ ...line, id, isNew });
  }
  save() {

  }
  render() {
    const { canEdit } = this.props;
    const { title, value } = this.state;
    return <FormGroup row>
      <Col sm="4">
        {canEdit ?
          <Input type="text" defaultValue={title} innerRef={this.titleRef} onBlur={e => this.changed('title', e)} /> :
          <Label className="form-label">{title}</Label>
        }
      </Col>
      <Col sm="8">
        {canEdit ?
          <Input type="text" defaultValue={value} innerRef={this.valueRef} onBlur={e => this.changed('value', e)} /> :
          <Label className="form-label form-label-lower">{value}</Label>
        }
      </Col>
    </FormGroup>
  }
}

let nextId = 1;
function getNextId() {
  return ++nextId;
}
function createNewLine() {
  return { title: '', value: '', isNew: true, id: getNextId() };
}

export default class AddlCreditsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lines:
        [
          ...props.lines.map(l => ({
            ...l,
            id: getNextId(),
            isNew: false,
          })),
          createNewLine()
        ]
    };
  }
  getNextId() {
    return ++nextId;
  }
  lineUpdated = (newLine) => {
    const { isNew, title, value, id } = newLine;
    const isEmpty = !title && !value;
    if (isEmpty && isNew) {
      return;
    }
    const lines = [...this.state.lines];
    const index = lines.findIndex(l => l.id === id);
    if (isEmpty && !isNew) {
      lines.splice(index, 1);
    } else {
      lines[index] = { ...newLine, isNew: false };
      if (isNew) {
        lines.push(createNewLine())
      }
    }
    this.setState({ lines });
    this.props.onUpdate(
      lines
        .filter(l => !l.isNew)
        .map(l => ({ title: l.title, value: l.value }))
    );
  }
  render() {
    const { canEdit } = this.props;
    const { lines } = this.state;
    return lines.map(l => <CreditLine key={l.id} line={l} canEdit={canEdit} lineUpdated={this.lineUpdated} />);
  }
}
