import React from "react"
import { Link } from "react-router-dom"
import { Alert, Nav, Breadcrumb, BreadcrumbItem } from 'reactstrap'

import GenericLoader from './generic-loader'
import ErrorMessage from './error-message'
import AwardForm from './award-form'

import defaultFetchOptions from './settings/fetch'

class Cart extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: true,
      customizedAwards: [],
      error: ''
    }
  }
  onDelete(index) {
    return () => {
      if (this.state.isLoading)
        return

      const fetchOptions = Object.assign({}, defaultFetchOptions, {
        method: 'delete'
      })
      fetch(`/api/cart/${index}`, fetchOptions).then(response => {
        if (response.status === 200) {
          this.refresh()
        } else if (response.ok) {
          return responseJson.then(errorJson => {
            throw new Error(errorJson.message)
          })
        } else {
          throw new Error('A internal error occurred.')
        }
      }).catch(err => {
        this.setState({ error: err.message })
      })
    }
  }
  onUpdate(index) {
    return cartItem => {
      const fetchOptions = Object.assign({}, defaultFetchOptions, {
        method: 'put',
        body: JSON.stringify(cartItem),
        headers: {
          'Content-Type': 'application/json'
        }
      })

      return fetch(`/api/cart/${index}`, fetchOptions).then(response => {
        if (response.status === 200) {
          return
        } else if (response.ok) {
          return responseJson.then(errorJson => {
            throw new Error(errorJson.message)
          })
        } else {
          throw new Error('A internal error occurred.')
        }
      }).catch(err => {
        this.setState({ error: err.message })
      })
    }
  }
  render() {
    return (
      <div>
        <Nav>
          <Breadcrumb>
            <BreadcrumbItem>Order cart</BreadcrumbItem>
          </Breadcrumb>
        </Nav>
        <hr className="mt-0" />

        <ErrorMessage message={this.state.error} />

        <GenericLoader isLoading={this.state.isLoading} />

        {this.state.customizedAwards.length > 0 ? (
          <Alert className="alert-international">
            <h4 className="alert-heading">Ready to check out?</h4>
            <p className="mb-0">
              <Link to="/checkout">Proceed to checkout.</Link>
            </p>
          </Alert>
        ) : (
            <Alert color="primary">
              <h4 className="alert-heading">Your cart is empty.</h4>
              <p className="mb-0">
                <Link to="/order">Add a new award.</Link>
              </p>
            </Alert>
          )}


        {this.state.customizedAwards.map((customizedAward, index) => (
          <AwardForm className="mb-4"
            key={customizedAward.cartItem.index}
            customizedAward={customizedAward}
            onUpdate={this.onUpdate(index).bind(this)}
            onDelete={this.onDelete(index).bind(this)}
          />
        ))}
      </div>
    )
  }
  refresh() {
    this.setState({ isLoading: true })
    const state = { isLoading: false }
    fetch(`/api/cart`, defaultFetchOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('An internal error occurred.')
        } else if (response.status === 200) {
          return response.json()
        } else {
          return responseJson.then(res => {
            throw new Error(res.message)
          })
        }
      }).then(responseJson => {
        Object.assign(state, responseJson)
        this.setState(state)
      }).catch((err) => {
        state.error = err.message
        this.setState(state)
      })
  }
  componentDidMount() {
    this.refresh()
  }
}

export default Cart