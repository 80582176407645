import React from 'react';
import { Button, Row, Col, Table } from 'reactstrap'
import { BrowserRouter as Router, Route, Link, Switch, IndexRoute, withRouter } from "react-router-dom"

import defaultFetchOptions from './settings/fetch'
import getAwardImageSrc from '../functions.js'
import GenericLoader from './generic-loader'

import SmallCartTable from './small-cart-table';
import StaticAddress from './static-address';

class CheckoutConfirm extends React.Component {
  constructor(props) {
    super(props)
    this.placeOrder = this.placeOrder.bind(this);
    this.state = { isLoading: true, isProcessing: false }
  }
  refresh() {
    this.setState({ isLoading: true })
    const paymentType = this.props.paymentType === 'wire' ?
      'wire' :
      ((this.props.card && /^3[47]/.test(this.props.card.number)) ?
        'credit,amex' :
        'credit,other');

    const state = { isLoading: false, paymentType };
    let query = `?payment=${encodeURIComponent(paymentType)}`;
    query += `&shipping=yes`;
    query += `&country=${encodeURIComponent(this.props.shippingAddress.country)}`;
    query += `&city=${encodeURIComponent(this.props.shippingAddress.city)}`;
    query += `&zip=${encodeURIComponent(this.props.shippingAddress.zip)}`;
    query += `&state=${encodeURIComponent(this.props.shippingAddress.state)}`;

    fetch(`/api/cart${query}`, defaultFetchOptions)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          return response.json().then(res => {
            throw new Error(res.message)
          })
        }
      }).then(cart => {
        Object.assign(state, { cart })
        this.setState(state)
      }).catch((err) => {
        state.cartError = err.message
        this.setState(state);
      });
  }
  placeOrder() {
    if (this.state.isProcessing) {
      return;
    }
    this.setState({ isProcessing: true });
    this.props.placeOrder().catch(err => {
      this.setState({ error: 'Could not complete order: ' + err.message, isProcessing: false });
    });
  }
  componentDidMount() {    
    if (
      !this.props.shippingAddress
      || (!this.props.billingAddress && this.props.useShippingAddress)
      || (this.props.paymentType === 'card' && !this.props.card)
    ) {
      return this.props.history.push('/checkout');
    }
    this.refresh()
  }
  formatCc(cardNumber) {
    return cardNumber.toString().substr(-4)
  }
  render() {
    return (
      <div>
        <GenericLoader isLoading={this.state.isLoading}/>
        {!this.state.isLoading && this.state.cart != undefined &&
          <div>
            <Row>
              <Col sm="4">
                <h5>Billing address</h5>
                <StaticAddress address={this.props.useShippingAddress ? this.props.shippingAddress : this.props.billingAddress} />
                {this.props.paymentType === 'credit' && (
                  <p className="mt-2">Credit card ending in {this.formatCc(this.props.card.number)}</p>
                )}
                {this.props.paymentType === 'wire' && (
                  <p className="mt-2">Payment will be made by wire transfer</p>
                )}
              </Col>
              <Col sm="4">
                <h5>Shipping address</h5>
                <StaticAddress address={this.props.shippingAddress} />
              </Col>
              <Col sm="4">
                <SmallCartTable cart={this.state.cart} />
              </Col>
            </Row>
            <hr />
            <p>Please review the above information and when ready, place your order.</p>
            <Button disabled={this.state.isProcessing} onClick={this.placeOrder}>Place order</Button>
          </div>
        }
        {this.state.cartError && (
          <p className="text-danger mt-2">Your cart could not be retrieved.<br/>The message from the server was: {this.state.cartError}.<br/><br/><a href="#" onClick={() => this.props.history.goBack()}>Click here to go back</a></p>
        )}
        {this.state.error && (
          <p className="text-danger mt-2">{this.state.error}</p>
        )}
        {this.state.isProcessing && (
          <div className="processing-overlay">
            <h4 className="mb-5">Please wait while your payment is being processed...</h4>
            <GenericLoader />
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(CheckoutConfirm);