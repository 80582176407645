import React from "react"
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom"
import { Label, Col, Table, FormGroup, Input, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'

import GenericLoader from './generic-loader'
import ErrorMessage from './error-message'

import defaultFetchOptions from './settings/fetch'


class WinnerRow extends React.Component {
  clickedHandler(event) {
    this.props.history.push(`${this.props.baseUrl}/${this.props.winner.WinnerItemID}`)
  }

  render() {
    const winner = this.props.winner
    return (
      <tr onClick={this.clickedHandler.bind(this)} style={{ cursor: 'pointer' }}>
        <td>{winner.Title}</td>
        <td>{winner.ItemCompany}</td>
        <td>{winner.Category}</td>
        {this.props.showCompetition &&
          <td>{winner.Competition}</td>
        }
        <td>{winner.Award}</td>
      </tr>
    )
  }
}

WinnerRow = withRouter(WinnerRow)

class SelectWinner extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: true,
      winners: [],
      competition: {},
      filter: '',
      competitions: [],
      categories: [],
      awards: [],
      categoryFilter: '',
      awardFilter: '',
      error: '',
      dropdownOpen: false,
      awardDropdownOpen: false,
      competitionDropdownOpen: false,
    }

    this.toggleCategoryDropdown = this.toggleCategoryDropdown.bind(this)
    this.toggleAwardDropdown = this.toggleAwardDropdown.bind(this)
    this.toggleCompetitionDropdown = this.toggleCompetitionDropdown.bind(this)
  }

  componentDidMount() {
    const state = { isLoading: false, competition: {}, winners: [] }
    fetch(`/api/competitions/${this.props.match.params.competitionId}`, defaultFetchOptions)
      .then((response) => {
        const responseJson = response.json()
        if (response.ok) {
          return responseJson
        } else {
          return responseJson.then((res) => {
            throw new Error(res.message)
          })
        }
      }).then((responseJson) => {
        const categories = []
        const awards = []
        const competitions = [];

        for (let winner of responseJson.winners) {
          const category = winner.Category
          if (categories.indexOf(category) === -1)
            categories.push(category)

          const award = winner.Award
          if (awards.indexOf(award) === -1)
            awards.push(award)

          const competition = winner.Competition;
          if (competitions.indexOf(competition) === -1)
            competitions.push(competition);
        }

        categories.sort((a, b) => (a || '').localeCompare(b || ''))

        state.competition = responseJson.competition
        state.competitions = competitions.filter(c => !!c);
        state.winners = responseJson.winners
        state.categories = categories
        state.awards = awards
      }).catch(err => {
        state.error = err.message
      }).then(() => this.setState(state))
  }

  filterChangedHandler(event) {
    const filter = event.target.value
    if (filter !== this.state.filter)
      this.setState({ filter })
  }

  getFilteredWinners() {
    const { competitionFilter, categoryFilter, filter, awardFilter, winners } = this.state;
    if (filter.trim().length || categoryFilter || awardFilter || competitionFilter) {
      const ciFilter = filter.toLowerCase();
      const testField = field => field && field.toLowerCase().indexOf(ciFilter) > -1
      return winners.filter(winner =>
        (!categoryFilter || (categoryFilter && winner.Category === categoryFilter)) &&
        (!awardFilter || (awardFilter && winner.Award === awardFilter)) &&
        (!competitionFilter || (winner.Competition === competitionFilter)) &&
        (!filter || (filter.length > 0 && (
          testField(winner.Title) ||
          testField(winner.ItemCompany)
        ))));
    } else {
      return winners;
    }
  }

  toggleCategoryDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  toggleAwardDropdown() {
    this.setState({
      awardDropdownOpen: !this.state.awardDropdownOpen
    });
  }

  toggleCompetitionDropdown() {
    this.setState({
      competitionDropdownOpen: !this.state.competitionDropdownOpen
    });
  }

  setCategoryFilter(categoryFilter) {
    return (evt) => {
      evt.preventDefault()
      this.setState({ categoryFilter: categoryFilter || '' })
    }
  }

  setCompetitionFilter(competitionFilter) {
    return (evt) => {
      evt.preventDefault()
      this.setState({ competitionFilter: competitionFilter || '' })
    }
  }

  setAwardFilter(awardFilter) {
    return (evt) => {
      evt.preventDefault()
      this.setState({ awardFilter: awardFilter || '' })
    }
  }

  render() {
    const { categories, competitions } = this.state;
    const colSize = competitions.length > 0 ? 3 : 4;
    return (
      <div>
        {this.state.winners.length > 0 && !this.state.isLoading && this.state.error.length === 0 &&
          <FormGroup row className="mb-5">
            <Col sm={colSize}>
              <Input type="text" name="filter" id="filter" placeholder="Filter results" onChange={this.filterChangedHandler.bind(this)} />
            </Col>
            <Col sm={colSize}>
              <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleCategoryDropdown}>
                <DropdownToggle style={{ width: '100%' }}>
                  {this.state.categoryFilter || 'Filter by category'}
                </DropdownToggle>
                <DropdownMenu style={{
                  maxHeight: '500px', 'overflowY': 'auto'
                }} >
                  <DropdownItem onClick={this.setCategoryFilter(null)}>All categories</DropdownItem>
                  <DropdownItem divider />
                  {
                    categories.map((category, index) => (
                      <DropdownItem onClick={this.setCategoryFilter(category)} key={index}>{category}</DropdownItem>
                    ))
                  }
                </DropdownMenu>
              </Dropdown>
            </Col>
            {competitions.length > 0 &&
              <Col sm={colSize}>
                <Dropdown isOpen={this.state.competitionDropdownOpen} toggle={this.toggleCompetitionDropdown}>
                  <DropdownToggle style={{ width: '100%' }}>
                    {this.state.competitionFilter || 'Filter by competition'}
                  </DropdownToggle>
                  <DropdownMenu style={{
                    maxHeight: '500px', 'overflowY': 'auto'
                  }} >
                    <DropdownItem onClick={this.setCompetitionFilter(null)}>All competitions</DropdownItem>
                    <DropdownItem divider />
                    {
                      competitions.map((competition, index) => (
                        <DropdownItem onClick={this.setCompetitionFilter(competition)} key={index}>{competition}</DropdownItem>
                      ))
                    }
                  </DropdownMenu>
                </Dropdown>
              </Col>
            }
            <Col sm={colSize}>
              <Dropdown isOpen={this.state.awardDropdownOpen} toggle={this.toggleAwardDropdown} style={{ width: '100%' }}>
                <DropdownToggle style={{ width: '100%' }}>
                  {this.state.awardFilter || 'Filter by award'}
                </DropdownToggle>
                <DropdownMenu style={{
                  maxHeight: '500px', 'overflowY': 'auto'
                }} >
                  <DropdownItem onClick={this.setAwardFilter(null)}>All awards</DropdownItem>
                  <DropdownItem divider />
                  {
                    this.state.awards.map((award, index) => (
                      <DropdownItem onClick={this.setAwardFilter(award)} key={index}>{award}</DropdownItem>
                    ))
                  }
                </DropdownMenu>
              </Dropdown>
            </Col>
          </FormGroup>
        }

        <ErrorMessage message={this.state.error} />

        <GenericLoader isLoading={this.state.isLoading} />

        {this.state.winners.length > 0 &&
          <Table hover size="sm">
            <thead>
              <tr>
                <th>Title</th>
                <th>Company</th>
                <th>Category</th>
                {this.state.competitions.length > 0 &&
                  <th>Competition</th>
                }
                <th>Award</th>
              </tr>
            </thead>
            <tbody>
              {this.getFilteredWinners().map((winner) => (<WinnerRow showCompetition={this.state.competitions.length > 0} baseUrl={this.props.match.url} key={winner.WinnerItemID} winner={winner} />))}
            </tbody>
          </Table>
        }
        {!this.state.isLoading && !this.state.error && this.state.winners.length === 0 &&
          <p className="text-center">No winners were found.</p>
        }
      </div>);
  }
}

export default withRouter(SelectWinner)
