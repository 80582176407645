import React from "react"
import { Button, Modal, ModalHeader, ModalFooter, ModalBody, Breadcrumb, BreadcrumbItem, Nav, Row, Col, FormGroup, Input, Label } from 'reactstrap'
import { BrowserRouter as Router, Route, Link, Switch, IndexRoute, withRouter } from "react-router-dom"

import CheckoutShipping from './checkout-shipping'
import CheckoutBilling from './checkout-billing'
import CheckoutConfirm from './checkout-confirm'
import CheckoutComplete from './checkout-complete'
import getCredentials from './settings/get-credentials'
import GenericLoader from "./generic-loader";
import defaultFetchOptions from './settings/fetch'


const CheckoutBreadcrumb = (props) => {
  const span = <span>{props.message}</span>
  const inner = props.active ?
    span :
    <Link to={props.url}>{span}</Link>
  return (
    <BreadcrumbItem active={props.active}>{inner}</BreadcrumbItem>
  )
}

class Checkout extends React.Component {
  constructor() {
    super()
    this.state = {
      card: { valid: false, number: '' },
      useShippingAddress: true,
      loadingCredentials: false,
      paymentResponse: {}
    }
    this.windowFocused = this.windowFocused.bind(this)
    this.placeOrder = this.placeOrder.bind(this)
  }
  render() {
    const url = this.props.match.url
    return (
      <div>
        <Nav>
          <Breadcrumb>
            <CheckoutBreadcrumb
              message="Checkout"
              url={url} />
            <Route path={`(${url}|${url}/billing|${url}/confirm)`} exact={true} render={({ match }) =>
              <CheckoutBreadcrumb
                message="Confirm shipping"
                active={match.url === '/checkout'}
                url={url} />
            } />
            <Route path={`${url}/(billing|confirm)`} render={({ match }) =>
              <CheckoutBreadcrumb
                message="Confirm billing"
                active={match.url === '/checkout/billing'}
                url={`${url}/billing`} />
            } />
            <Route path={`${url}/confirm`} render={({ match }) =>
              <CheckoutBreadcrumb
                message="Place order"
                active={match.isExact}
                url={`${url}/confirm`} />
            } />
            <Route path={`${url}/complete`} render={({ match }) =>
              <CheckoutBreadcrumb
                message="Complete"
                active={match.isExact}
                url={`${url}/complete`} />
            } />
          </Breadcrumb>
        </Nav>
        <hr className="mt-0" />

        {this.state.user && (
          <Row>
            <Col sm="12">
              <Switch>
                <Route exact path={url} render={() => (
                  <CheckoutShipping address={this.state.shippingAddress || this.state.initialAddress}
                    handleSave={this.handleShippingSaved.bind(this)}
                    countries={this.state.countries}
                  />
                )} />
                <Route exact path={`${url}/billing`} render={() => (
                  <CheckoutBilling
                    useShippingAddress={this.state.useShippingAddress}
                    address={this.state.useShippingAddress ? this.state.shippingAddress : (this.state.billingAddress || this.state.shippingAddress)}
                    countries={this.state.countries}
                    handleSave={this.handleBillingSaved.bind(this)} />
                )} />
                <Route exact path={`${url}/confirm`} render={() =>
                  <CheckoutConfirm
                    billingAddress={this.state.billingAddress}
                    shippingAddress={this.state.shippingAddress}
                    useShippingAddress={this.state.useShippingAddress}
                    placeOrder={this.placeOrder}
                    card={this.state.paymentType === 'credit' ? this.state.card : null}
                    paymentType={this.state.paymentType}
                  />
                } />
                <Route exact path={`${url}/complete`} render={() =>
                  <CheckoutComplete
                    paymentResponse={this.state.paymentResponse} />
                } />

              </Switch>
            </Col>  
          </Row>
        )}

        <div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
            <ModalHeader toggle={this.toggle}>Log in to continue</ModalHeader>
            <ModalBody>
              In order to continue, you'll need to log into the New York Festivals site.<br />
              <br />
              <a href="https://api.newyorkfestivals.com/login" target="_blank">Please click here</a> to log in.<br />
              When you have logged in, please return to this page.
              <br />
              <br />
              <GenericLoader isLoading={this.state.loadingCredentials} />
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.returnToCart.bind(this)}>Return to cart</Button>
            </ModalFooter>
          </Modal>
        </div>

      </div>
    )
  }

  returnToCart() {
    this.props.history.push('/cart')
  }

  placeOrder() {
    const payload = this.state;
    const options = Object.assign({}, defaultFetchOptions, {
      method: 'post',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })
    return fetch('/api/cart/payment', options).then(response => {
      let jsonPromise = response.json();
      if (!response.ok) {
        jsonPromise = jsonPromise.then(errorJson => {
          throw new Error(errorJson.message);
        });
      }
      return jsonPromise;
    }).then(response => {
      console.log(response);
      this.setState({ paymentResponse: response });
      this.props.history.push('/checkout/complete');
    });
  }

  handleBillingSaved(billing) {
    this.setState(billing)
    this.props.history.push('/checkout/confirm')
  }

  handleShippingSaved(shipping) {
    this.setState(shipping)
    this.props.history.push('/checkout/billing')
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.windowFocused);
  }

  windowFocused() {
    if (this.state.loadingCredentials || this.state.user) {
      return;
    }
    this.tryLoadCredentials();
  }

  tryLoadCredentials() {
    this.setState({ loadingCredentials: true });
    getCredentials().then(user => {
      console.log(user);
      const defaults = {
        name: `${user.FName} ${user.LName}`,
        email: user.email,
        address1: user.ConAdd1,
        address2: user.ConAdd2,
        address3: user.ConAdd3,
        city: user.ConCity,
        phone: user.ConPhone
      }
      this.setState({
        userId: user.ConID,
        email: user.email,
        user: true,
        initialAddress: Object.assign({}, defaults),
        billingAddress: null,
        shippingAddress: null,
        shipToBillingAddress: true,
        loadingCredentials: false,
        modal: false
      })
    }).catch(err => {
      console.log(err);
      this.setState({ modal: true, loadingCredentials: false })
    });

  }

  componentDidMount() {
    fetch('/api/countries')
      .then(res => res.json())
      .then(countries => {
        countries = countries
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(c => ({
            ...c,
            states: c.states.sort((a, b) => a.name.localeCompare(b.name))
          }));
        this.setState({ countries });
        window.addEventListener('focus', this.windowFocused)
        this.tryLoadCredentials();
      });
  }
}

export default withRouter(Checkout)