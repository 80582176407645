import React from "react"
import { BrowserRouter as Router, Route, Link, Switch, IndexRoute } from "react-router-dom"
import { Row, Col, Card, CardBody, Breadcrumb, BreadcrumbItem, Nav } from 'reactstrap'

import SelectCompetition from './select-competition'
import SelectWinner from './select-winner'
import SelectAward from './select-award'
import CustomizeAward from './customize-award'

const OrderBreadcrumb = (props) => {
  const span = <span>{props.message}</span>
  const inner = props.active ?
    span :
    <Link to={props.url}>{span}</Link>
  return (
    <BreadcrumbItem active={props.active}>{inner}</BreadcrumbItem>
  )
}

export default class Order extends React.Component {
  constructor() {
    super()
    this.state = {
      competitions: []
    }
  }
  render() {
    const url = this.props.match.url
    return (
      <div>
        <Nav>
          <Breadcrumb>
            <OrderBreadcrumb
              message="Order"
              url={url} />
            <Route path={url} render={({ match }) =>
              <OrderBreadcrumb
                message="Select year and competition"
                active={match.isExact}
                url={url} />
            } />
            <Route path={`${url}/:competitionId`} render={({ match }) =>
              <OrderBreadcrumb
                message="Select winner"
                active={match.isExact}
                url={`${url}/${match.params.competitionId}`} />
            } />
            <Route path={`${url}/:competitionId/:winnerId`} render={({ match }) =>
              <OrderBreadcrumb
                message="Select award"
                active={match.isExact}
                url={`${url}/${match.params.competitionId}/${match.params.winnerId}`} />
            } />
            <Route path={`${url}/:competitionId/:winnerId/:awardId`} render={({ match }) =>
              <OrderBreadcrumb
                message="Customize award"
                active={match.isExact}
                url={`${url}/${match.params.competitionId}/${match.params.winnerId}/${match.params.awardId}`} />
            } />

          </Breadcrumb>
        </Nav>

        <Row>
          <Col sm="12">
            <hr className="mt-0" />
            <Switch>
              <Route exact path={url} component={SelectCompetition} />
              <Route exact path={`${url}/:competitionId`} component={SelectWinner} />
              <Route exact path={`${url}/:competitionId/:winnerId`} component={SelectAward} />
              <Route exact path={`${url}/:competitionId/:winnerId/:awardId`} component={CustomizeAward} />
            </Switch>
          </Col>
        </Row>
      </div>
    )
  }
}
