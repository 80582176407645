import React from "react"
import { Button, Row, Col, FormGroup, Input, Label } from 'reactstrap'
import { BrowserRouter as Router, Route, Link, Switch, IndexRoute, withRouter } from "react-router-dom"

import AddressForm from './address-form'

class CheckoutShipping extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      valid: false,
      shippingAddress: Object.assign({}, props.address)
    };
  }
  render() {
    return (
      <div>
        <Row>
          <Col sm="3">
            <h5 className="mt-1">Shipping address</h5>
          </Col>
          <Col sm="9">
            <AddressForm address={this.props.address} onAddressChange={this.onAddressChange.bind(this)} countries={this.props.countries} />
          </Col>
        </Row>
        <hr />
        <div style={{ 'textAlign': 'right' }}>
          <Button disabled={!this.state.valid} onClick={this.save.bind(this)}>Enter billing information</Button>
        </div>
      </div >
    )
  }
  save() {
    this.props.handleSave(this.state)
  }
  onAddressChange(shippingAddress, valid) {
    this.setState({ shippingAddress, valid })
  }
}

export default withRouter(CheckoutShipping)