import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export const AwardUploadResultModal = ({
  result,
  isOpen,
  toggle,
  onConfirm,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        {result?.success
          ? "Ready to upload"
          : "Issues with database identified"}
      </ModalHeader>
      <ModalBody>
        {result?.success === true && (
          <p>{result.records.length} records identified.</p>
        )}
        {result?.success === false && (
          <>
            <p className="text-danger">
              {result.errors.length} errors occurred while verifying the
              database. Please correct the issues and try uploading again.
            </p>
            <ul>
              {result.errors.map((e, i) => (
                <li key={i}>{e}</li>
              ))}
            </ul>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Close</Button>
        {result?.success === true && (
          <Button color="success" onClick={onConfirm}>
            Apply changes
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};
