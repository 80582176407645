import React from "react";
import Payment from "payment";
import { Row, Col, FormGroup, Label, Alert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCcJcb,
  faCcDinersClub,
  faCcDiscover,
} from "@fortawesome/fontawesome-free-brands";

export default class CreditCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      number: null,
      exp_month: null,
      exp_year: null,
      cvc: null,
    };

    this.setCardType = this.setCardType.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetCard = this.resetCard.bind(this);
  }

  resetCard() {
    this.setState({ number: null, exp_month: null, exp_year: null, cvc: null });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.resetCard();

    const { refs } = this;
    const number = refs.number.value;
    const expiration = refs.expiration.value.split("/");
    const exp_month = parseInt(expiration[0], 10);
    const exp_year = parseInt(expiration[1], 10);
    const cvc = refs.cvc.value;
    const card = { number, exp_month, exp_year, cvc };
  }

  onChange(e) {
    e.preventDefault();

    const { refs } = this;
    const number = refs.number.value;
    const expiration = refs.expiration.value.split("/");
    const exp_month = parseInt(expiration[0], 10);
    const exp_year = parseInt(expiration[1], 10);
    const cvc = refs.cvc.value;
    const valid =
      !isNaN(exp_month) && exp_month > 0 && exp_month < 13 && !isNaN(exp_year);

    const card = { number, exp_month, exp_year, cvc, valid };
    this.props.onCardChange(card);
  }

  setCardType(e) {
    const type = Payment.fns.cardType(e.target.value);
    const cards = document.querySelectorAll("[data-brand]");
    [].forEach.call(cards, (element) => {
      if (element.getAttribute("data-brand") === type) {
        element.classList.add("active");
      } else {
        element.classList.remove("active");
      }
    });
    this.onChange(e);
  }

  renderCardList() {
    return (
      <ul className="credit-card-list clearfix mt-0">
        <li>
          <FontAwesomeIcon data-brand="visa" icon={faCcVisa} />
        </li>
        <li>
          <FontAwesomeIcon data-brand="amex" icon={faCcAmex} />
        </li>
        <li>
          <FontAwesomeIcon data-brand="mastercard" icon={faCcMastercard} />
        </li>
        <li>
          <FontAwesomeIcon data-brand="jcb" icon={faCcJcb} />
        </li>
        <li>
          <FontAwesomeIcon data-brand="discover" icon={faCcDiscover} />
        </li>
        <li>
          <FontAwesomeIcon data-brand="dinersclub" icon={faCcDinersClub} />
        </li>
      </ul>
    );
  }

  renderCardForm() {
    return (
      <form>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <Label>Card Number</Label>
              <input
                onKeyUp={this.setCardType.bind(this)}
                className="form-control"
                type="text"
                ref="number"
                placeholder="Card Number"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={5}>
            <FormGroup>
              <Label>Expiration</Label>
              <input
                className="form-control text-center"
                type="text"
                ref="expiration"
                placeholder="MM/YYYY"
                onKeyUp={this.onChange.bind(this)}
              />
            </FormGroup>
          </Col>
          <Col xs={6} sm={{ size: 4, offset: 3 }}>
            <FormGroup>
              <Label>CVC</Label>
              <input
                className="form-control text-center"
                type="text"
                ref="cvc"
                placeholder="CVC"
                onKeyUp={this.onChange.bind(this)}
              />
            </FormGroup>
          </Col>
        </Row>
      </form>
    );
  }

  renderCard() {
    const { number, exp_month, exp_year, cvc, token } = this.state;
    return number ? (
      <Alert color="info">
        <h5>{number}</h5>
        <p className="exp-cvc">
          <span>
            {exp_month}/{exp_year}
          </span>
          <span>{cvc}</span>
        </p>
        <em>{token}</em>
      </Alert>
    ) : (
      ""
    );
  }

  componentDidMount() {
    const { number, expiration, cvc } = this.refs;
    Payment.formatCardNumber(number);
    Payment.formatCardExpiry(expiration);
    Payment.formatCardCVC(cvc);
  }

  render() {
    return (
      <div className="CreditCard">
        {this.renderCardList()}
        {this.renderCardForm()}
        {this.renderCard()}
      </div>
    );
  }
}

CreditCard.propTypes = {};
