import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export const DeleteAssetModal = ({
  isOpen,
  asset,
  onDeleteConfirmed,
  toggle,
}) => {
  return (
    <Modal toggle={toggle} isOpen={isOpen}>
      <ModalHeader toggle={toggle}>Delete {asset?.filename}</ModalHeader>
      <ModalBody>This cannot be undone</ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Cancel</Button>
        <Button color="danger" onClick={() => onDeleteConfirmed(asset)}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};
