import Axios from "axios";
import { useResource } from "../../hooks/useResource";
import GenericLoader from "../generic-loader";
import React, { Fragment } from "react";
import { apiResponse } from "../../functions/apiResource";

export const AvailableAwardsList = () => {
  const { result, isLoading } = useResource(
    () => apiResponse(Axios.get("/api/admin/awards")),
    []
  );
  return (
    <>
      {result && (
        <dl className="row">
          {result?.awards?.map((a) => (
            <Fragment key={a.sku}>
              <dt class="col-sm-3">{a.sku}</dt>
              <dd class="col-sm-9">{a.description}</dd>
            </Fragment>
          ))}
        </dl>
      )}
      <GenericLoader isLoading={isLoading} />
    </>
  );
};
