export function apiResponse(promise) {
  return promise
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      throw new Error(
        err.response?.data?.message || "An internal error occurred"
      );
    });
}
