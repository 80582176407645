import React from "react"
import { BrowserRouter as Router, Route, Link } from "react-router-dom"
import ErrorMessage from './error-message'
import { Button, Row, Col, ListGroupItem, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, ListGroup } from 'reactstrap'

import GenericLoader from './generic-loader'

import defaultFetchOptions from './settings/fetch'


const CompetitionRow = (props) => (
  <Link
    className="list-group-item list-group-item-action"
    to={props.url}>
    {props.competition.CmptnYear} {props.competition.Official}
  </Link>
)

class SelectCompetition extends React.Component {
  constructor() {
    super()
    this.state = this.getLoadingState()
  }

  getLoadingState() {
    return {
      isLoading: true,
      competitions: [],
      filteredCompetitions: [],
      years: [],
      error: '',
      year: null,
      yearDropdownOpen: false
    }
  }

  yearClicked(year) {
    return () => this.changeYear(year)
  }

  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  render() {
    return (
      <div>
        <ErrorMessage message={this.state.error} />

        <GenericLoader isLoading={this.state.isLoading} />

        {this.state.years.length > 0 && (
          <Row>
            {this.state.years.map(year => (
              <Col sm="2" key={year}>
                <Button style={{ 'width': '100%' }}
                  active={this.state.year === year}                  
                  onClick={this.yearClicked(year)}
                  className="mb-2">
                  {year}
                </Button>
              </Col>
            ))}
          </Row>
        )}
        <hr className="mt-2"/>
        <Row>
          <Col sm="12">
            {this.state.filteredCompetitions.length > 0 &&
              <ListGroup>
                {this.state.filteredCompetitions.map(competition => (
                  <CompetitionRow key={competition.CmptnID} competition={competition} url={`${this.props.match.url}/${competition.CmptnID}`} />
                ))}
              </ListGroup>
            }
          </Col>
        </Row>
      </div>
    )
  }

  changeYear(year) {
    const filteredCompetitions = this.state.competitions.filter(
      competition => competition.CmptnYear === year)
    this.setState({ year, filteredCompetitions })
  }

  componentDidMount() {
    const state = { isLoading: false }
    fetch('/api/competitions', defaultFetchOptions)
      .then(response => {
        if (!response.ok)
          throw new Error('An internal error occurred.')

        const responseJson = response.json()
        if (response.status === 200) {
          return responseJson
        } else {
          return responseJson.then(res => {
            throw new Error(res.message)
          })
        }
      }).then(responseJson => {
        const competitions = state.competitions = responseJson.competitions
        const years = []
        for (let competition of competitions) {
          const year = competition.CmptnYear
          if (year.toString().length === 4 && years.indexOf(year) === -1)
            years.push(year)
        }
        years.sort((a, b) => b - a)
        state.filteredCompetitions = []
        state.years = years
        this.setState(state)
      }).catch(err => {
        state.error = err.message
        this.setState(state)
      })
  }
}

export default SelectCompetition