import React, { useState } from "react";
import { Button, Card, CardBody, Row, Col, Input } from "reactstrap";
import fileDialog from "file-dialog";
import { useResource } from "../../hooks/useResource";
import { fileToDataUri } from "../../functions/fileToDataUri";
import { apiResponse } from "../../functions/apiResource";
import axios from "axios";
import { DeleteAssetModal } from "./DeleteAssetModal";

export const AssetsView = () => {
  const [isWorking, setIsWorking] = useState(false);
  const {
    result,
    isLoading,
    error: assetsError,
    refresh: refreshAssets,
  } = useResource(() => apiResponse(axios.get("/api/admin/assets")), []);
  const { assets } = result ?? {};
  const [deleteModalProps, setDeleteModalProps] = useState({
    isOpen: false,
    asset: null,
  });

  async function onDeleteConfirmed(asset) {
    console.log(asset);
    setDeleteModalProps({ ...deleteModalProps, isOpen: false });
    setIsWorking(true);
    try {
      const response = await apiResponse(
        axios.delete(`/api/admin/assets/${asset.id}`)
      );
      refreshAssets();
    } catch (err) {
      console.error(err);
    } finally {
      setIsWorking(false);
    }
  }

  async function onUploadClicked() {
    const files = await fileDialog({ accept: "image/*", multiple: false });
    const [file] = files;
    if (!file) {
      return;
    }
    setIsWorking(true);
    try {
      const dataUri = await fileToDataUri(file);
      const filename = file.name;
      const asset = { dataUri, filename };
      const response = await apiResponse(
        axios.post("/api/admin/assets", { asset })
      );
      refreshAssets();
    } catch (err) {
      console.error(err);
    } finally {
      setIsWorking(false);
    }
  }

  function onDeleteClicked(asset) {
    return () => {
      setDeleteModalProps({ isOpen: true, asset });
    };
  }

  return (
    <>
      <DeleteAssetModal
        toggle={() =>
          setDeleteModalProps({ ...deleteModalProps, isOpen: false })
        }
        onDeleteConfirmed={onDeleteConfirmed}
        {...deleteModalProps}
      />
      <div>
        <div className="mb-3">
          <Button
            color="primary"
            onClick={onUploadClicked}
            disabled={isWorking}
          >
            Upload a new image
          </Button>
        </div>
        <Row>
          {assets?.map((a) => (
            <Col md={3} key={a.id}>
              <Card style={{ marginBottom: "2rem" }}>
                <CardBody>
                  <div className="asset-image-container mb-2">
                    <img src={`/public/img/awards/${a.filename}`} />
                  </div>
                  <div className="mt-2">{a.filename}</div>
                  <div className="mt-2">
                    <Button
                      size="sm"
                      color="danger"
                      onClick={onDeleteClicked(a)}
                    >
                      Delete
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};
