import React from 'react';
import getAwardImageSrc from '../functions.js'
import { Table } from 'reactstrap';

export default (props) => (
  <Table size="sm" responsive>
    <thead>
      <tr>
        <th></th>
        <th>Item</th>
        <th>Price</th>
      </tr>
    </thead>
    <tbody>
      {props.cart.customizedAwards.map((customizedAward, index) =>
        <tr key={index}>
          <td className="checkout-table-cell">
            <img className="checkout-image" src={getAwardImageSrc(customizedAward.award)} />
          </td>
          <td className="checkout-table-cell">{customizedAward.award.name} x {customizedAward.cartItem.quantity}</td>
          <td className="checkout-table-cell">{customizedAward.total}</td>
        </tr>
      )}
    </tbody>
    <tfoot>
      <tr>
        <td></td>
        <td>Subtotal</td>
        <td>{props.cart.subtotal}</td>
      </tr>
      <tr>
        <td></td>
        <td>Shipping</td>
        <td>{props.cart.shippingFee}</td>
      </tr>
      <tr className={props.highlightProcessing ? 'font-weight-bold text-danger' : ''}>
        <td></td>
        <td>Processing fee</td>
        <td>{props.cart.processingFee}</td>
      </tr>
      <tr>
        <td></td>
        <td>Total</td>
        <td>{props.cart.total}</td>
      </tr>
    </tfoot>
  </Table>
)