import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Collapse,
  Nav,
  NavLink,
  Navbar,
  NavbarBrand,
  NavItem,
} from "reactstrap";
import { ProfileContext } from "../contexts/ProfileContext";

export default () => {
  const { profile } = useContext(ProfileContext);
  return (
    <Navbar expand="md" className="mb-5 nyf-navbar">
      <Container>
        <NavbarBrand>
          <img className="navbar-image" src="/public/img/navbar_brand.png" />
        </NavbarBrand>
        <Collapse navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink tag={Link} to="/order">
                Order a new award
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/cart">
                Cart
              </NavLink>
            </NavItem>
            {profile?.isAdmin && (
              <NavItem>
                <NavLink tag={Link} to="/admin">
                  Admin
                </NavLink>
              </NavItem>
            )}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};
