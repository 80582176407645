import React from "react"
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom"
import { FormGroup, Label, Col, Row, Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap'

import GenericLoader from './generic-loader'
import ErrorMessage from './error-message'
import AwardCard from './award-card'

import defaultFetchOptions from './settings/fetch'

const WinnerField = (props) => {
  const value = props.value
  return value ? (
    <React.Fragment>
      <dt className={props.wide ? 'col-sm-6' : 'col-sm-3'}>
        {props.title}
      </dt>
      <dd className={props.wide ? 'col-sm-6' : 'col-sm-9'}>
        {props.value}
      </dd>
    </React.Fragment>
  ) : null
}

class SelectAward extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: true,
      winner: {
        awards: [],
        winner: {},
        talent: []
      },
      error: ''
    }
  }
  awardClicked(award) {
    console.log(award)
    this.props.history.push(`${this.props.match.url}/${award.id}`)
  }
  render() {
    const baseUrl = this.props.match.url
    return (
      <div>
        <ErrorMessage message={this.state.error} />

        <GenericLoader isLoading={this.state.isLoading} />

        <Row className="mb-5">
          <Col sm="6">
            <dl className="row">
              <WinnerField title="Title" value={this.state.winner.winner.Title} />
              <WinnerField title="Advertiser" value={this.state.winner.winner.Brand} />
            </dl>

            <dl className="row">
              <WinnerField title="Company" value={this.state.winner.winner.CompName} />
              <WinnerField title="Country" value={this.state.winner.winner.Country} />
            </dl>

            <dl className="row">
              <WinnerField title="Category" value={this.state.winner.winner.Category} />
            </dl>
          </Col>
          <Col sm="6">
            <dl className="row">

              {this.state.winner.talent.map((talent, index) => (
                <WinnerField title={talent.title} value={talent.name} key={index} wide />
              ))}
            </dl>
          </Col>
        </Row>

        {this.state.winner.awards.length > 0 &&
          <div>
            <p className="mb-5">
            There are different types of awards to choose from, depending on what type of award you originally won. Choose one of them below. All awards are custom engraved and take up to two to three months from ordering to delivery.
            </p>
            <Row>
              {this.state.winner.awards.map(award => {
                const url = award.can_order
                  ? `${baseUrl}/${award.id}`
                  : null;
                return <Col sm="4" className="mb-4" style={{ display: 'flex' }} key={award.id}>
                  <AwardCard style={{ flexGrow: 1 }} award={award} url={url} />
                </Col>;
              })}
            </Row>
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    const state = { isLoading: false }
    const winnerId = this.props.match.params.winnerId
    fetch(`/api/winners/${winnerId}`, defaultFetchOptions)
      .then((response) => {
        const responseJson = response.json()
        if (response.ok) {
          return responseJson
        } else {
          return responseJson.then((res) => {
            throw new Error(res.message)
          })
        }
      }).then((responseJson) => {
        state.winner = responseJson
        this.setState(state)
      }).catch((err) => {
        state.error = err.message
        this.setState(state)
      })
  }
}

export default withRouter(SelectAward)