import {
  faDownload,
  faExclamationCircle,
  faSpinner,
  faThumbsUp,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import fileDownload from "js-file-download";
import React, { useState } from "react";
import { Button, Col, FormGroup, Row } from "reactstrap";
import { fileToDataUri } from "../../functions/fileToDataUri";
import fileDialog from "file-dialog";
import { apiResponse } from "../../functions/apiResource";
import { AwardUploadResultModal } from "./AwardUploadResultModal";
import { AvailableAwardsList } from "./AvailableAwardsList";
import { Alert } from "reactstrap";

export const AwardsView = () => {
  const [action, setAction] = useState();
  const [resultModalProps, setResultModalProps] = useState({ isOpen: false });
  const [uploadResult, setUploadResult] = useState(null);
  async function onDownloadClicked() {
    const datePart = new Intl.DateTimeFormat("en-CA", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      year: "numeric",
      month: "2-digit",
      day: "numeric",
    })
      .format(new Date())
      .replace(/[\:\,\s]/gi, "");
    const reportFilename = `NYF awards database - ${datePart}.xlsx`;
    try {
      setAction("downloading");
      const response = await axios.get("/api/admin/awards-db", {
        responseType: "blob",
      });
      fileDownload(response.data, reportFilename);
    } catch (err) {
      console.error(err);
    } finally {
      setAction();
    }
  }

  async function onUploadClicked() {
    setUploadResult(null);
    const files = await fileDialog({
      accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      multiple: false,
    });
    const [file] = files;
    if (!file) {
      return;
    }
    const result = await uploadDatabase(file, true);
    const toggle = () => {
      setResultModalProps({
        ...resultModalProps,
        onConfirm: null,
        isOpen: false,
      });
    };
    const onConfirm = result.success
      ? async () => {
          toggle();
          const result = await uploadDatabase(file);
          setUploadResult(result);
        }
      : null;
    setResultModalProps({
      isOpen: true,
      toggle,
      onConfirm,
      result,
    });
  }

  async function uploadDatabase(file, preflight = false) {
    setAction("uploading");
    try {
      const dataUri = await fileToDataUri(file);
      const filename = file.name;
      const spreadsheet = { dataUri, filename };
      const response = await apiResponse(
        axios.post("/api/admin/awards-db", {
          spreadsheet,
          preflight: preflight === true,
        })
      );
      return response;
    } catch (err) {
      return {
        success: false,
        errors: [err.message],
      };
    } finally {
      setAction();
    }
  }

  return (
    <div>
      <AwardUploadResultModal {...resultModalProps} />
      <FormGroup row className={"mb-4"}>
        <Col md={12}>
          <h5>Database management</h5>
        </Col>
        <Col md={6}>
          <Button
            className="w-100 text-center mb-1"
            disabled={!!action}
            onClick={onDownloadClicked}
          >
            <FontAwesomeIcon
              className="mr-2"
              spin={action === "downloading"}
              icon={action === "downloading" ? faSpinner : faDownload}
            />
            Download award database
          </Button>
        </Col>
        <Col md={6}>
          <Button
            className="w-100 text-center mb-1"
            disabled={!!action}
            onClick={onUploadClicked}
          >
            <FontAwesomeIcon
              className="mr-2"
              spin={action === "uploading"}
              icon={action === "uploading" ? faSpinner : faUpload}
            />
            Upload award database
          </Button>
        </Col>
        {uploadResult && (
          <Col md={12}>
            {uploadResult.success ? (
              <Alert color="success">
                <FontAwesomeIcon icon={faThumbsUp} className="mr-2" />
                Successfully uploaded {uploadResult.records.length} records!
              </Alert>
            ) : (
              <Alert color="danger">
                <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
                Errors occurred while uploading the database.
                {uploadResult.errors.map((err, i) => (
                  <p key={i}>{err}</p>
                ))}
              </Alert>
            )}
          </Col>
        )}
      </FormGroup>

      <FormGroup row>
        <Col md={12}>
          <h5>Available awards</h5>
          <AvailableAwardsList />
        </Col>
      </FormGroup>
    </div>
  );
};
