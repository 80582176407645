import React, { useContext } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Nav,
  Row,
  Col,
  NavItem,
  NavLink,
} from "reactstrap";
import { Route, Link, withRouter, Redirect } from "react-router-dom";
import Login from "./login";
import Order from "./order";
import { AssetsView } from "./AssetsView";
import { OrdersView } from "./OrdersView";
import { AwardsView } from "./AwardsView";
import { ProfileContext } from "../../contexts/ProfileContext";

const OrderBreadcrumb = (props) => {
  const span = <span>{props.message}</span>;
  const inner = props.active ? span : <Link to={props.url}>{span}</Link>;
  return <BreadcrumbItem active={props.active}>{inner}</BreadcrumbItem>;
};

class Admin extends React.Component {
  static contextType = ProfileContext;
  render() {
    const { context } = this;
    async function onLoginComplete() {
      await context.refreshProfile();
      this.history.push("/admin/orders");
    }
    return (
      <div>
        <Nav>
          <Breadcrumb>
            <OrderBreadcrumb message="Administrator Panel" active />
            <Route
              path="/admin/login"
              render={({ match }) => (
                <OrderBreadcrumb
                  message="Login"
                  active={match.isExact}
                  url="/admin/login"
                />
              )}
            />
            {this.context?.profile?.isAdmin && (
              <>
                <Route
                  path="/admin/orders/"
                  render={({ match }) => (
                    <OrderBreadcrumb
                      message="View orders"
                      active={match.isExact}
                      url="/admin/orders/"
                    />
                  )}
                />
                <Route
                  path="/admin/orders/:orderId"
                  render={({ match }) => (
                    <OrderBreadcrumb
                      message="View order"
                      active={match.isExact}
                      url={`/admin/orders/${match.params.orderId}`}
                    />
                  )}
                />
                <Route
                  path="/admin/assets"
                  render={({ match }) => (
                    <OrderBreadcrumb
                      message="Assets"
                      active={match.isExact}
                      url={`/admin/assets`}
                    />
                  )}
                />
                <Route
                  path="/admin/awards"
                  render={({ match }) => (
                    <OrderBreadcrumb
                      message="Awards"
                      active={match.isExact}
                      url={`/admin/awards`}
                    />
                  )}
                />
              </>
            )}
          </Breadcrumb>
        </Nav>
        {this.context?.profile?.isAdmin && (
          <Nav>
            <NavItem>
              <NavLink tag={Link} to="/admin/orders" className="pl-0">
                View orders
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/admin/assets">
                Award image gallery
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/admin/awards">
                Award data maintenance
              </NavLink>
            </NavItem>
          </Nav>
        )}
        <Row>
          <Col sm="12">
            <hr className="mt-0" />
            <Route
              exact
              path="/admin/login"
              render={() => <Login onComplete={onLoginComplete} />}
            />
            {context.profile?.isAdmin && (
              <>
                <Route path="/admin/assets" render={() => <AssetsView />} />
                <Route path="/admin/awards" render={() => <AwardsView />} />
                <Route
                  exact
                  path="/admin/orders"
                  render={() => <OrdersView />}
                />
                <Route
                  exact
                  path="/admin/orders/:orderId"
                  render={(props) => (
                    <Order orderId={props.match.params.orderId} />
                  )}
                />
              </>
            )}
            {context.profile && (
              <Route
                exact
                path="/admin"
                render={() => (
                  <Redirect
                    to={
                      context.profile?.isAdmin
                        ? "/admin/orders"
                        : "/admin/login"
                    }
                  />
                )}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(Admin);
