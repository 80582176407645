import React from "react"
import { Row, Col } from 'reactstrap'
import { withRouter } from "react-router-dom"

class CheckoutComplete extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div>
        <Row>
          <Col sm="12">
            <h3>Thank you!</h3>
            <p>Your order <b>{this.props.paymentResponse.orderNumber}</b> has been submitted. You will receive a receipt by email.</p>

            {this.props.paymentResponse.paymentType === 'credit' && (
              <dl className="row">
                <dt className="col-sm-3">Payment type</dt>
                <dd className="col-sm-9">Credit card</dd>

                <dt className="col-sm-3">Authorization code</dt>
                <dd className="col-sm-9">{this.props.paymentResponse.details.authCode}</dd>

                <dt className="col-sm-3">Transaction ID</dt>
                <dd className="col-sm-9">{this.props.paymentResponse.details.transactionId}</dd>
              </dl>
            )}

            {this.props.paymentResponse.paymentType === 'wire' && (
              <dl className="row">
                <dt className="col-sm-3">Payment type</dt>
                <dd className="col-sm-9">Wire transfer</dd>
              </dl>
            )}

            <p><a href="http://www.newyorkfestivals.com/">Click here</a> to return to the New York Festivals website.</p>

          </Col>
        </Row>
      </div >
    )
  }
}

export default withRouter(CheckoutComplete)