import React from "react"
import { withRouter } from "react-router-dom"
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap'
import Lightbox from 'react-images'
import FontAwesome from 'react-fontawesome'

import getAwardImageSrc from '../functions.js'

class AwardCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = { currentImage: false };

    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
  }
  openLightbox(event) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ lightboxIsOpen: true });
  }
  closeLightbox() {
    this.setState({ lightboxIsOpen: false });
  }
  render() {
    return (
      <Card className="award-card"
        onClick={this.props.url ?
          () => this.props.history.push(this.props.url) :
          null}
        style={{...(this.props.style || {}), ...(this.props.url ? { cursor: 'pointer' } : null)}}>
        <CardBody>
          <CardTitle>{this.props.award.name}</CardTitle>
          <CardSubtitle>{`$${this.props.award.price}`}</CardSubtitle>
        </CardBody>

        <Lightbox
          showImageCount={false}
          backdropClosesModal
          images={[
            {
              src: this.props.award.image ?
                `/public/img/awards/${this.props.award.image}` :
                'https://placeholdit.imgix.net/~text?txtsize=33&txt=Award%20image&w=318&h=318'
            }
          ]}
          isOpen={this.state.lightboxIsOpen}
          onClose={this.closeLightbox}
        />

        <div className="award-card-image-container">
          <a href="#" onClick={this.openLightbox} className="award-card-image-zoom">
            <FontAwesome name="search-plus" size="2x" />
          </a>
          <img width="100%" src={
            this.props.award.image ?
              `/public/img/awards/${this.props.award.image}` :
              'https://placeholdit.imgix.net/~text?txtsize=33&txt=Award%20image&w=318&h=318'
          }
            alt="Card image cap" />
        </div>
        {!this.props.award.can_order &&
          <CardBody>
            <p>
              To order this award please contact <a href="mailto:fran.pollaro@newyorkfestivals.com">fran.pollaro@newyorkfestivals.com</a>
            </p>
          </CardBody>
        }
      </Card >
    )
  }
}

export default withRouter(AwardCard)