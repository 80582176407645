import { useCallback, useEffect, useMemo, useReducer, useRef } from "react";

export function resourceHookReducer(lastState, action) {
  if (action.type === "start") {
    return {
      isLoading: true,
      result: action.reset ? undefined : lastState.result,
    };
  } else if (action.type === "succeeded") {
    return {
      isLoading: false,
      result: action.result,
    };
  } else if (action.type === "failed") {
    return {
      isLoading: false,
      result: undefined,
      error: action.error,
    };
  }
}

export function useResource(resourceGetter, dependencies, options) {
  const [state, dispatch] = useReducer(resourceHookReducer, {
    isLoading: false,
    result: undefined,
  });
  const reqId = useRef(0);

  const refresh = useCallback(
    async function (reset = false) {
      const thisId = ++reqId.current;
      dispatch({ type: "start", reset });
      if (options?.wait != null) {
        await new Promise((resolve) => {
          setTimeout(() => resolve(), options.wait);
        });
        if (reqId.current !== thisId) {
          return;
        }
      }
      try {
        const result = await resourceGetter();
        if (reqId.current !== thisId) {
          return;
        }
        dispatch({ type: "succeeded", result });
      } catch (err) {
        const { message, statusCode } = err;
        dispatch({
          type: "failed",
          error: err,
        });
      }
    },
    [options]
  );

  useEffect(() => {
    refresh();
    return () => {
      // Will stop refresh from dispatching any more events
      reqId.current++;
    };
  }, [...dependencies]);

  const result = useMemo(
    () => ({
      ...state,
      refresh,
    }),
    [state, refresh]
  );

  return result;
}
