import React from 'react';

export default (props) => (
  <div className="static-address">
    <p>{props.address.name}</p>
    {!(!props.address.company) && <p>{props.address.company}</p>}
    <p>{props.address.address1}</p>
    {!(!props.address.address2) && <p>{props.address.address2}</p>}
    {!(!props.address.address3) && <p>{props.address.address3}</p>}
    {!(!props.address.phone) && <p>{props.address.phone}</p>}
    <p>{props.address.city}, {props.address.state && `${props.address.state}, `}{props.address.country}</p>
    <p>{props.address.zip}</p>
  </div>
);