import React from "react"
import { Button, Row, Col, FormGroup, Input, Label } from 'reactstrap'
import { BrowserRouter as Router, Route, Link, Switch, IndexRoute, withRouter } from "react-router-dom"

import AddressForm from './address-form'
import CreditCardForm from './creditcard-form'

class CheckoutBilling extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      useShippingAddress: props.useShippingAddress,
      card: Object.assign({ valid: false }, props.card),
      billingAddress: Object.assign({}, props.address, { valid: false }),
      formValid: false,
      paymentType: 'credit',
      addressValid: false
    }
  }
  useShippingAddressChanged(e) {
    this.updateFormValid({ useShippingAddress: e.target.checked })
  }
  payByWireTransferChanged(e) {
    this.updateFormValid({ paymentType: e.target.checked ? 'wire' : 'credit' });
  }
  updateFormValid(state) {
    state = Object.assign({}, this.state, state)
    const formValid = (state.useShippingAddress || state.addressValid) &&
      (state.paymentType === 'wire' || state.card.valid);
    state.formValid = formValid
    this.setState(state)
  }
  render() {
    return (
      <div>
        <Row>
          <Col sm="12">
            <Row>
              <Col sm="3">
                <h5 className="mt-1">Billing Address</h5>
                <FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="checkbox" defaultChecked={this.state.useShippingAddress} onChange={this.useShippingAddressChanged.bind(this)} />
                      Use shipping address
                    </Label>
                  </FormGroup>
                </FormGroup>
              </Col>
              <Col sm="9">
                {!this.state.useShippingAddress && (
                  <AddressForm
                    countries={this.props.countries}
                    address={this.state.billingAddress}
                    onAddressChange={this.onAddressChange.bind(this)}
                  />
                )}

              </Col>
            </Row>
          </Col>
        </Row>
        <hr className="mt-1" />
        <Row>
          <Col sm="3">
            <h5 className="mt-1">Payment information</h5>
          </Col>
          <Col sm="9">
            <CreditCardForm onCardChange={this.onCardChange.bind(this)} />
            <small className="mt-2">For credit card payments, the following charges apply:<br />
              Visa/MC: 3% credit card fee<br />
              Amex: 3.2% credit card fee<br />
            </small>
            <hr />
            <FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" defaultChecked={this.state.paymentType === 'wire'} onChange={this.payByWireTransferChanged.bind(this)} />
                  Pay by wire transfer<br />
                  <small className="mt-2">A $35 wire transfer fee will apply</small>
                </Label>
              </FormGroup>
            </FormGroup>

          </Col>
        </Row>
        <hr />
        <div className="mt-2" style={{ 'textAlign': 'right' }}>
          <Button disabled={!this.state.formValid} onClick={this.save.bind(this)}>Review order</Button>
        </div>
      </div >
    )
  }
  save() {
    this.props.handleSave(this.state)
  }
  onCardChange(card) {
    this.updateFormValid({ card })
  }
  onAddressChange(billingAddress, addressValid) {    
    this.updateFormValid({ billingAddress, addressValid })
  }
  componentDidMount() {
    if (
      !this.props.address
    ) {
      return this.props.history.push('/checkout');
    }
    this.updateFormValid(this.state)
  }
}

export default withRouter(CheckoutBilling)