import React from "react"
import { ListGroup, ListGroupItem, Table, Button } from 'reactstrap'
import { BrowserRouter as Router, Route, Link, Switch, IndexRoute, withRouter } from "react-router-dom"
import defaultFetchOptions from '../settings/fetch'
import GenericLoader from '../generic-loader'
import ConfirmationModal from '../confirmation-modal';

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modals: [] };
  }
  getStatusDescription(statusCode) {
    switch (statusCode) {
      case 'P':
        return 'Production';
      case null:
        return 'Pending';
      default:
        return 'Pre-production';
    }
  }
  showOrder(order) {
    const url = `/admin/orders/${order.id}`;
    this.props.history.push(url);
  }
  showApproveModal(order) {
    const onAction = (buttonOption) => {
      if (buttonOption.action === 'approve') {
        this.props.approve(buttonOption.order);
      }
    }

    const modal = <ConfirmationModal
      key={this.state.modals.length}
      title={
        <span>
          Approve {order['po']} for production
        </span>
      }
      body={
        <span>
          {order['po']} will be approved and placed into production. Please make sure all fields are correct before continuing.
          <br /><br />
          <b>This cannot be undone</b>
        </span>
      }
      buttonOptions={[
        { title: 'Approve', action: 'approve', color: 'success', order },
        { title: 'Cancel' }
      ]}
      onAction={onAction}
    />
    this.setState({ modals: this.state.modals.concat([modal]) });
    // this.props.refresh();
  }
  showTracking(trackingNumber) {
    const uri = `http://www.fedex.com/Tracking?tracknumbers=${encodeURIComponent(trackingNumber)}`;
    window.open(uri);
  }
  render() {
    return (
      <div>
        <div>
          {this.state.modals.map(modal => modal)}
        </div>
        <Table size="sm" style={{ fontSize: '0.9em' }}>
          <thead>
            <tr>
              <th>Date</th>
              <th>PO</th>
              <th>RSO #</th>
              <th>User ID</th>
              <th>Email</th>
              <th>Shipping</th>
              <th>Payment</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.props.orders.map(order => (
              <tr key={order.id}>
                <td>{new Date(order['created']).toLocaleDateString()}</td>
                <td>{order['po']}</td>
                <td>{order['order_num']}</td>
                <td>{order['user_id']}</td>
                <td>{order['email']}</td>
                <td>
                  {order['shipping_name'].trim() || order['shipping_company']}<br />
                  {order['shipping_address1']}<br />
                  {order['shipping_address2'] && <span>{order['shipping_address2']} <br /></span>}
                  {order['shipping_address3'] && <span>{order['shipping_address3']} <br /></span>}
                  {order['shipping_city']}{order['shipping_state'] && `, ${order['shipping_state']}`}<br />
                  {order['shipping_country']}<br />
                  {order['shipping_zip']}<br />
                </td>
                <td>
                  {order.payment_type.indexOf('credit') > -1 && 'Credit'}
                  {order.payment_type === 'wire' && <span className="text-danger font-weight-bold">Wire transfer</span>}
                </td>
                <td>
                  {order.approved
                    ? <span>{order['status']}</span>
                    : <span>Awaiting approval</span>
                  }
                </td>
                <td>
                  <Button
                    size="sm"
                    disabled={order['missing_pricing'] || order['approved']}
                    color={order['missing_pricing'] ?
                      'default' :
                      'success'}
                    onClick={() => this.showApproveModal(order)}
                  >
                    Approve{order['approved'] ? 'd' : ''}
                  </Button><br />
                  <Button size="sm" onClick={() => this.showOrder(order)} className="mt-1 mr-1">View</Button>
                  {order.tracking && order.tracking.map(t => (
                    <Button size="sm" onClick={() => this.showTracking(t)} className="mt-1 mr-1">Track</Button>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}
export default withRouter(Orders);