import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

export default class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };
    this.close = this.close.bind(this);
  }
  close() {
    this.setState({ isOpen: false });
  }
  buttonClicked(e, buttonOption) {
    e.preventDefault();
    if (buttonOption.action && this.props.onAction) {
      this.props.onAction(buttonOption);
    }
    this.close();
  }
  render() {
    return (
      <div>
        <Modal isOpen={this.state.isOpen} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{this.props.title}</ModalHeader>
          <ModalBody>{this.props.body}</ModalBody>
          <ModalFooter>
            {this.props.buttonOptions.map((buttonOption, index) =>
              <span key={index}>
                <Button
                  color={buttonOption.color || 'default'}
                  onClick={(e) => this.buttonClicked(e, buttonOption)}
                >{buttonOption.title || buttonOption.action}
                </Button>
              </span>
            )}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
};