import defaultFetchOptions from './fetch'

const fetchOptions = { method: 'get', credentials: 'include' }

export default () => {
  return fetch('/api/config', { method: 'get' }).then(response => {
    if (response.ok) {
      return response.json()
        .then(j => fetch(j.credentialsUrl, fetchOptions));
    } else if (response.ok) {
      throw new Error('An internal error occurred.')
    } else {
      throw new Error(`Received a ${response.status} response from the server`)
    }
  }).then(response => {
    if (response.ok) {
      return response.json()
    } else if (response.ok) {
      throw new Error('An internal error occurred.')
    } else {
      throw new Error(`Received a ${response.status} response from the server`)
    }
  }).then(responseJson => {
    console.log(responseJson);
    if (responseJson.is_loggedin === 'no') {
      throw new Error('The user is not logged in.');
    }
    return responseJson;
  })
}