import React from "react"
import { Table, Row, Col, Button } from 'reactstrap'
import { withRouter } from "react-router-dom"
import defaultFetchOptions from '../settings/fetch'
import GenericLoader from '../generic-loader'
import ConfirmationModal from '../confirmation-modal';
import AwardForm from '../award-form';
import SmallCartTable from '../small-cart-table';
import StaticAddress from '../static-address';

export default class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = { order: null, isLoading: true };
  }
  componentDidMount() {
    const state = { isLoading: false }
    const orderId = this.props.orderId
    fetch(`/api/admin/orders/${orderId}`, defaultFetchOptions)
      .then((response) => {
        const responseJson = response.json();
        if (response.ok) {
          return responseJson;
        } else {
          return responseJson.then((res) => {
            throw new Error(res.message)
          });
        }
      }).then((responseJson) => {
        state.order = responseJson.order;
      }).catch((err) => {
        state.error = err.message;
      }).then(() => {
        this.setState(state);
      });
  }
  downloadClicked = (cartItemIndex) => {
    window.location.href = `/api/admin/orders/${this.props.orderId}/cart/${cartItemIndex}/credits`;
  }
  onUpdate(index) {
    return cartItem => {
      const fetchOptions = Object.assign({}, defaultFetchOptions, {
        method: 'put',
        body: JSON.stringify(cartItem),
        headers: {
          'Content-Type': 'application/json'
        }
      })

      return fetch(`/api/admin/orders/${this.state.order.id}/cart/${index}`, fetchOptions).then(response => {
        if (response.status === 200) {
          return
        } else if (response.ok) {
          return responseJson.then(errorJson => {
            throw new Error(errorJson.message)
          })
        } else {
          throw new Error('A internal error occurred.')
        }
      }).catch(err => {
        this.setState({ error: err.message })
      })
    }
  }

  render() {
    return (
      <div>
        <GenericLoader isLoading={this.state.isLoading} />
        {this.state.order && (
          <div>
            <h4 className="mb-3">
              <b>
                {this.state.order['po']}
              </b>
              (RSO #{this.state.order['order_num']})
              {this.state.order.has_invoice &&
                <a 
                className="ml-1 small"
                href={`/api/admin/orders/${this.props.orderId}/invoice`}
                target="_blank">
                  View invoice
                  </a>
              }
            </h4>
            <Row>
              <Col sm="12">
                <Table>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>PO</th>
                      <th>RSO #</th>
                      <th>User ID</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key={this.state.order.id}>
                      <td>{new Date(this.state.order['created']).toLocaleDateString()}</td>
                      <td>{this.state.order['po']}</td>
                      <td>{this.state.order['order_num']}</td>
                      <td>{this.state.order['user_id']}</td>
                      <td>{this.state.order['email']}</td>
                    </tr>
                  </tbody>
                </Table>
                <hr />
              </Col>
            </Row>

            <Row>
              <Col sm="4">
                <h5>Billing address</h5>
                <StaticAddress address={this.state.order['billing_address']} />
              </Col>
              <Col sm="4">
                <h5>Shipping address</h5>
                <StaticAddress address={this.state.order['shipping_address']} />
              </Col>
              <Col sm="4">
                <SmallCartTable cart={this.state.order.cart} highlightProcessing={this.state.order['payment_type'] === 'wire'} />
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                {this.state.order.cart.customizedAwards.map((customizedAward, index) => (
                  <AwardForm className="mb-4"
                    disabled={this.state.order.warehouse !== customizedAward.award.warehouse && 'This item is fulfilled as a part of a separate order.'}
                    onUpdate={this.onUpdate(index).bind(this)}
                    showEntryItemId={true}
                    key={customizedAward.cartItem.index}
                    customizedAward={customizedAward}
                    downloadClicked={() => this.downloadClicked(index)}
                  />
                ))}
              </Col>
            </Row>
          </div>
        )}
      </div>
    )
  }
}