import React from "react"
import { Button, Modal, ModalHeader, ModalFooter, ModalBody, Breadcrumb, BreadcrumbItem, Nav, Row, Col, FormGroup, Input, Label, Form } from 'reactstrap'
import { BrowserRouter as Router, Route, Link, Switch, IndexRoute, withRouter } from "react-router-dom"
import defaultFetchOptions from '../settings/fetch'
import GenericLoader from '../generic-loader'

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, username: '', password: '' };
    this.state.isLoading = false;
  }
  onSubmit(e) {
    e.preventDefault();
    const payload = {
      user: this.state.user,
      password: this.state.password
    };

    const fetchOptions = Object.assign({}, defaultFetchOptions, {
      method: 'post',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });

    this.setState({ isLoading: true, error: null });
    const state = { isLoading: false };
    fetch('/api/admin/login', fetchOptions).then(response => {
      response.json().then(json => {
        if (!response.ok) {
          return this.setState(Object.assign(state, { error: json.message }));
        } else {
          return this.props.onComplete();
        }
      });
    }).catch(err => {
      this.setState(Object.assign(state, { message: 'An internal server error occurred.' }));
    });
  }
  userChanged(e) {
    e.preventDefault();
    this.setState({ user: e.target.value });
  }
  passwordChanged(e) {
    e.preventDefault();
    this.setState({ password: e.target.value });
  }
  render() {
    return (
      <div>
        <Row>
          <Col sm={{ size: 4, offset: 4 }}>
            <GenericLoader isLoading={this.state.isLoading} />
            {!this.state.isLoading && (
              <div>
                <Form onSubmit={this.onSubmit.bind(this)}>
                  <h4 className="mb-4">Administrator login</h4>
                  <FormGroup>
                    <Label>User</Label>
                    <Input type="text" placeholder="" onChange={this.userChanged.bind(this)} defaultValue={this.state.user} />
                  </FormGroup>
                  <FormGroup>
                    <Label>Password</Label>
                    <Input type="password" placeholder="" ref="password" onChange={this.passwordChanged.bind(this)} defaultValue={this.state.password} />
                  </FormGroup>
                  <FormGroup className="mt-4">
                    <Button>Submit</Button>
                  </FormGroup>
                </Form>
                {this.state.error && (
                  <p className="text-danger">{this.state.error}</p>
                )}
              </div>
            )}
          </Col>
        </Row>
      </div>
    )
  }
}

export default withRouter(Login);