import React, { useMemo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import Order from "./order";
import Cart from "./cart";
import { Row, Col, Container } from "reactstrap";
import Admin from "./admin/admin";
import NyfFooter from "./footer";

import HeaderNav from "./header-nav";
import Checkout from "./checkout";
import { useResource } from "../hooks/useResource";
import Axios from "axios";
import { ProfileContext } from "../contexts/ProfileContext";
import { apiResponse } from "../functions/apiResource";

export const Main = () => {
  const { result, refresh: refreshProfile } = useResource(
    () => apiResponse(Axios.get("/api/profile")),
    []
  );
  const profileValue = useMemo(
    () => ({
      profile: result?.profile,
      refreshProfile,
    }),
    [result, refreshProfile]
  );
  return (
    <ProfileContext.Provider value={profileValue}>
      <Router>
        <React.Fragment>
          <HeaderNav />
          <Container className="mb-5">
            <Row>
              <Col xs="12">
                <Route path="/order" component={Order} />
                <Route path="/cart" component={Cart} />
                <Route path="/checkout" component={Checkout} />
                <Route path="/admin" component={Admin} />
                <Route
                  path="/"
                  exact
                  render={({}) => (
                    <>
                      <Redirect to={"/order"} />
                    </>
                  )}
                />
              </Col>
            </Row>
          </Container>
          <NyfFooter />
        </React.Fragment>
      </Router>
    </ProfileContext.Provider>
  );
};
