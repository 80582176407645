import React from "react"
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom"
import { Form, Button, Input, FormGroup, Label, Col, Row, Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap'

import GenericLoader from './generic-loader'
import ErrorMessage from './error-message'
import AwardCard from './award-card'
import AwardForm from './award-form'

import defaultFetchOptions from './settings/fetch'

class CustomizeAward extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: true,
      lines: [],
      customizedAward: null,
      error: ''
    }
  }
  onSubmit(cartItem) {
    const options = Object.assign({}, defaultFetchOptions, {
      method: 'post',
      body: JSON.stringify(cartItem),
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })
    fetch('/api/cart', options).then(response => {
      if (response.status === 200) {
        return
      } else {
        return response.json().then(res => {
          throw new Error(res.message)
        })
      }
    }).then(() =>
      this.props.history.push('/cart')
    ).catch((err) => {
      this.setState({ error: err.message })
    })
  }
  render() {
    const baseUrl = this.props.match.url
    return (
      <div>
        <ErrorMessage message={this.state.error} />

        <GenericLoader isLoading={this.state.isLoading} />

        {this.state.customizedAward !== null &&
          <AwardForm
            customizedAward={this.state.customizedAward}
            onSubmit={this.onSubmit.bind(this)}
          />
        }
      </div>
    )
  }

  componentDidMount() {
    const state = { isLoading: false }
    const awardId = this.props.match.params.awardId
    const winnerId = this.props.match.params.winnerId

    fetch(`/api/winners/${winnerId}/awards/${awardId}`, defaultFetchOptions)
      .then((response) => {
        const responseJson = response.json()
        if (response.ok) {
          return responseJson
        } else {
          return responseJson.then((res) => {
            throw new Error(res.message)
          })
        }
      }).then((responseJson) => {
        state.customizedAward = responseJson
        this.setState(state)
      }).catch((err) => {
        state.error = err.message
        this.setState(state)
      })
  }
}

export default withRouter(CustomizeAward)